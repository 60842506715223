import React, { Component } from 'react';
import { Grid } from '@material-ui/core/';
import DiceButton from '../Dice/DiceButton';
import PlayerButton from '../Player/PlayerButton';
import EnemyButton from '../Enemy/EnemyButton';
import PlayerList from '../Player/PlayerList';
import EnemyList from '../Enemy/EnemyList';
import PlayerDisplay from '../Player/PlayerDisplay';
import EnemyDisplay from '../Enemy/EnemyDisplay';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

class CombatSim extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playerList: this.props.playerList,
			enemyList: this.props.enemyList,
			enemyRolls: [],
			playerIndex: 0,
			enemyIndex: 0,
			openPlayer: false,
			openEnemy: false,
			baseAssets: {},
			enemyAssets: {},
			miscAssets: {},
			playerAssets: {},
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			baseAssets: nextProps.baseAssets,
			enemyAssets: nextProps.enemyAssets,
			miscAssets: nextProps.miscAssets,
			playerAssets: nextProps.playerAssets
		};
	}

	saveGame = (stuff) => {
		fetch(process.env.REACT_APP_ASSET_URL + '/save_game', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(stuff),
		})
			.then((resp) => resp.json())
			.then((data) => {
				// console.log(data);
			});
	};

	render() {
		let enemyRolls = [];
		const getPlayerList  = (pList) => {
			this.setState((prevState) => ({
				playerList: [...prevState.playerList, pList],
			}));
			this.props.setPlayerList([...this.state.playerList]);
		};

		const getEnemyList = (eList) => {
			this.setState((prevState) => ({
				enemyList: [...prevState.enemyList, eList],
			}));
			this.props.setEnemyList([...this.state.enemyList]);
		};

		const roundIncrease=()=>{
			this.props.incrementRound();
			this.props.playerRoundUpdate();
			enemyRolls = [];
		};

		const updatePlayerList = (newPlayer, playerIndex) => {
			const newPlayerList = [...this.state.playerList];
			newPlayerList[playerIndex] = newPlayer;
			const slotsArray = [
				'Boots',
				'Chest',
				'Hands',
				'Helm',
				'Legs',
				'Main_Hand',
				'None',
				'Off_Hand',
				'Shoulders',
				'Two_Handed',
			];

			for (let i = 0; i < slotsArray.length; i++) {
				newPlayerList[playerIndex]['Total_Vitality'] =
					newPlayerList[playerIndex]['Total_Vitality'] +
					newPlayer['Gear'][slotsArray[i]]['Vitality'];
				newPlayerList[playerIndex]['Total_Efficiency'] =
					newPlayerList[playerIndex]['Total_Efficiency'] +
					newPlayer['Gear'][slotsArray[i]]['Efficiency'];
				newPlayerList[playerIndex]['Total_Regen'] =
					newPlayerList[playerIndex]['Total_Regen'] +
					newPlayer['Gear'][slotsArray[i]]['Regen'];
				newPlayerList[playerIndex]['Total_Critical_Rating'] =
					newPlayerList[playerIndex]['Total_Critical_Rating'] +
					newPlayer['Gear'][slotsArray[i]]['Critical_Strike'];
			}
			this.setState(() => ({ playerList: [...newPlayerList] }));
			this.props.setPlayerList([...newPlayerList]);
		};

		const updateEnemyList = (newEnemy, enemyIndex) => {
			const newEnemyList = [...this.state.enemyList];
			newEnemyList[enemyIndex] = newEnemy;
			this.setState(() => ({ enemyList: [...newEnemyList] }));
			this.props.setEnemyList([...newEnemyList]);
		};

		const getPlayerIndex = (idx) => {
			this.setState({ playerIndex: idx });
			this.setState({ openPlayer: true });
		};

		const getEnemyIndex = (idx) => {
			this.setState({ enemyIndex: idx });
			this.setState({ openEnemy: true });
		};

		const retirePlayer = (idx) => {
			const newPlayerList = [...this.state.playerList];
			newPlayerList.splice(idx, 1);
			this.setState({ playerList: [...newPlayerList] });
			this.props.setPlayerList([...newPlayerList]);
		};

		const vanquishEnemy = (idx) => {
			const newEnemyList = [...this.state.enemyList];
			newEnemyList.splice(idx, 1);
			this.setState({ enemyList: [...newEnemyList] });
			this.props.setEnemyList([...newEnemyList]);
		};

		const hidePlayerDisplay = () => {
			this.setState({ openPlayer: false });
			// console.log(this.state.playerList[this.state.playerIndex]);
		};

		const hideEnemyDisplay = () => {
			this.setState({ openEnemy: false });
			// console.log(this.state.enemyList[this.state.enemyIndex]);
		};

		const getEnemyRolls = () => {
			enemyRolls = [];
			this.state.enemyList.forEach(enemy => {
				let roll = Math.floor(Math.random() * 20) + 1;
				let diceOutcome = this.state.enemyAssets.combatProbsD20[enemy['Rank']]['rollArray'][roll-1];
				enemyRolls.push(diceOutcome);
			});
			this.setState({enemyRolls: [...enemyRolls]});
		};

		const testData = {
			cheese: 'delicious',
			types: ['cheddar', 'swiss', 'gouda', 'brie'],
		};
		return (
			<div>
				<Typography
					variant='h4'
					textAlign='center'
					style={{ padding: '0.5rem 0.5rem' }}
				>
					Combat Simulator
				</Typography>
				<Typography textAlign='center' style={{ color: 'black' }}>
					Round: {this.props.roundCounter}
				</Typography>
				<Grid
					container
					direction='column'
					alignItems='center'
					justifyContent='center'
					spacing={1}
				>
					<Grid item>
						<Grid
							item
							container
							justifyContent='center'
							style={{ padding: '0.25rem' }}
						>
							{/* <Button
								variant='outlined'
								onClick={() => this.saveGame(testData)}
							>
								save
							</Button> */}
						</Grid>
						<Grid
							container
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							spacing={3}
						>
							<Grid item xs={2}>
								<DiceButton
									enemyAssets={this.state.enemyAssets}
								/>
							</Grid>
							<Grid item xs={2}>
								<PlayerButton
									getPlayerList={getPlayerList}
									playerList={this.state.playerList}
									baseAssets={this.state.baseAssets}
									playerAssets={this.state.playerAssets}
								/>
							</Grid>
							<Grid item xs={2}>
								<EnemyButton
									getEnemyList={getEnemyList}
									baseAssets={this.state.baseAssets}
									enemyList={this.state.enemyList}
									enemyAssets={this.state.enemyAssets}
								/>
							</Grid>
							<Grid item xs={2}>
								<Button
									variant='outlined'
									onClick={()=>roundIncrease()}
									style={{ backgroundColor: '#B80000' }}
								>
									Next Round
								</Button>
							</Grid>
							<Grid item xs={2}>
								<Button
									disabled={enemyRolls.length > 0 ? true : false}
									variant='outlined'
									onClick={()=>{getEnemyRolls();}}
									style={{ backgroundColor: '#000000',color: 'white' }}
								>
									Enemy Rolls
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					direction='row'
					justifyContent='space-evenly'
					alignContent='center'
				>
					<Grid item>
						<Grid item>
							<Grid
								container
								direction='column'
								alignContent='center'
								justifyContent='center'
							>
								<Grid item>
									<PlayerList
										playerList={this.props.playerList}
										getPlayerIndex={getPlayerIndex}
									/>
								</Grid>
								<Grid item>
									<PlayerDisplay
										player={
											this.state.playerList[
												this.state.playerIndex
											]
										}
										playerIndex={this.state.playerIndex}
										updatePlayerList={updatePlayerList}
										updateEnemyList={updateEnemyList}
										enemyList={this.state.enemyList}
										hidePlayerDisplay={hidePlayerDisplay}
										retirePlayer={retirePlayer}
										open={this.state.openPlayer}
										baseAssets={this.state.baseAssets}
										playerAssets={this.state.playerAssets}
										miscAssets={this.state.miscAssets}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid item>
							<Grid
								container
								direction='column'
								alignContent='center'
								justifyContent='center'
							>
								<Grid item>
									<EnemyList
										enemyList={this.state.enemyList}
										getEnemyIndex={getEnemyIndex}
									/>
								</Grid>
								<Grid item>
									<EnemyDisplay
										enemy={this.state.enemyList[this.state.enemyIndex]}
										index={this.state.enemyIndex}
										enemyRolls={this.state.enemyRolls}
										miscAssets={this.state.miscAssets}
										enemyIndex={this.state.enemyIndex}
										enemyAssets={this.state.enemyAssets}
										updateEnemyList={updateEnemyList}
										hideEnemyDisplay={hideEnemyDisplay}
										vanquishEnemy={vanquishEnemy}
										open={this.state.openEnemy}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default CombatSim;
