import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import myTheme from './components/UI/Theme';
import Header from './components/UI/Header';
import Welcome from './components/UI/Welcome';
import CombatSim from './components/Tools/CombatSim';

class App extends Component {
	state = {
		selectedIndex: 0,
		myValue: 0,
		roundCounter: 1,
		playerList: [],
		enemyList: [],
		baseAssets: {},
		enemyAssets: {},
		miscAssets: {},
		playerAssets: {},
		isLoggedIn: false,
	};

	getAssetData = () => {
		fetch(process.env.REACT_APP_ASSET_URL + '/base', { method: 'GET' })
			.then((resp) => resp.json())
			.then((data) => {
				this.setState({ ...this.state, baseAssets: { ...data } });
			});
		fetch(process.env.REACT_APP_ASSET_URL + '/player', {
			method: 'GET',
		})
			.then((resp) => resp.json())
			.then((data) => {
				this.setState({
					...this.state,
					playerAssets: { ...data },
				});
			});
		fetch(process.env.REACT_APP_ASSET_URL + '/enemy', {
			method: 'GET',
		})
			.then((resp) => resp.json())
			.then((data) => {
				this.setState({
					...this.state,
					enemyAssets: { ...data },
				});
				
			});
		
		fetch(process.env.REACT_APP_ASSET_URL + '/misc', { method: 'GET' })
			.then((resp) => resp.json())
			.then((data) => {
				this.setState({ ...this.state, miscAssets: { ...data } });
			});
		
	};

	setIsLoggedIn = () => {
		this.setState({ ...this.state, isLoggedIn: true });
	};

	setIsLoggedOut = () => {
		this.setState({ ...this.state, isLoggedIn: false });
	};

	setMyValue = (input) => {
		this.setState({ myValue: input });
	};

	setSelectedIndex = (input) => {
		this.setState({ selectedIndex: input });
	};

	setPlayerList = (pList) => {
		this.setState(() => ({ playerList: [...pList] }));
	};

	setEnemyList = (eList) => {
		this.setState(() => ({ enemyList: [...eList] }));
	};

	playerRoundUpdate = () => {
		let tempPlayers = [...this.state.playerList];
		tempPlayers.map((player, index) => {
			tempPlayers[index] = {
				...player,
				Energy:
					player['Energy'] <= 8
						? player['Energy'] + 2
						: player['Energy'] <= 9
						? player['Energy'] + 1
						: player['Energy'] + 0,
				Threat:
					player['Threat'] > 0
						? player['Threat'] - 1
						: player['Threat'] - 0,
			};
		});
		// this.setState(() => ({ ...this.state,playerList: [...tempPlayers] }));
		this.setPlayerList([...tempPlayers]);
	};

	incrementRound = () => {
		this.setState((prevState) => ({
			...prevState,
			roundCounter: this.state.roundCounter + 1,
		}));
	};

	render() {
		return (
			<ThemeProvider theme={myTheme}>
				<BrowserRouter>
					<Header
						isLoggedIn={this.state.isLoggedIn}
						myValue={this.state.myValue}
						setMyValue={this.setMyValue}
						selectedIndex={this.state.selectedIndex}
						setSelectedIndex={this.setSelectedIndex}
					/>
					<Switch>
						<Route
							exact
							path='/'
							render={(props) => (
								<Welcome
									{...props}
									base={
										Object.keys(this.state.baseAssets)
											.length === 0
											? false
											: true
									}
									enemy={
										Object.keys(this.state.enemyAssets)
											.length === 0
											? false
											: true
									}
									misc={
										Object.keys(this.state.miscAssets)
											.length === 0
											? false
											: true
									}
									player={
										Object.keys(this.state.playerAssets)
											.length === 0
											? false
											: true
									}
									myValue={this.state.myValue}
									setMyValue={this.setMyValue}
									selectedIndex={this.state.selectedIndex}
									setSelectedIndex={this.setSelectedIndex}
									getAssetData={this.getAssetData}
								/>
							)}
						/>
						{Object.keys(this.state.baseAssets).length === 0 ||
						Object.keys(this.state.baseAssets).length === 0 ||
						Object.keys(this.state.baseAssets).length === 0 ||
						Object.keys(this.state.baseAssets).length === 0 ? (
							'...Loading...'
						) : (
							<Route
								path='/CombatSim'
								render={(props) => (
									<CombatSim
										{...props}
										setPlayerList={this.setPlayerList}
										playerList={this.state.playerList}
										setEnemyList={this.setEnemyList}
										enemyList={this.state.enemyList}
										baseAssets={this.state.baseAssets}
										miscAssets={this.state.miscAssets}
										enemyAssets={this.state.enemyAssets}
										playerAssets={this.state.playerAssets}
										getAssetData={this.getAssetData}
										roundCounter={this.state.roundCounter}
										incrementRound={this.incrementRound}
										playerRoundUpdate={
											this.playerRoundUpdate
										}
									/>
								)}
							/>
						)}
						<Redirect to='/' />
					</Switch>
				</BrowserRouter>
			</ThemeProvider>
		);
	}
}

export default App;
