import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Select, Grid, Typography } from '@material-ui/core/';
import MenuItem from '@mui/material/MenuItem';
import PlayerInfo from './PlayerInfo';
import PlayerAbilities from './PlayerAbilities';
// import CasinoIcon from '@mui/icons-material/Casino';
import PlayerColorPicker from './PlayerColorPicker';

const defaultPlayer = require('./defaultPlayer');

const useStyles = makeStyles((theme) => ({
	dialogPaperTwo: {
		backgroundColor: 'white',
		height: '720px',
	},
}));

const PlayerDisplay = (props) => {
	const [showColor, setShowColor] = useState(false);
	const [diceResult, setDiceResult] = useState('Roll Dice!');
	// const [critPcnt, setCritPcnt] = useState(0);
	let critPcnt = 0;
	let criticalStrikeStatSums = 0;

	const getCritPercentage = (datas) => {
		criticalStrikeStatSums = datas.criticalStrikeStatSums;
		critPcnt = parseFloat(datas.crit_pcnt);
	};
	
	const handleShowColor = () => {
		setShowColor(true);
	};

	const handleHideColor = () => {
		setShowColor(false);
	};

	const XP = props.playerAssets
		? props.playerAssets.XPTrack
		: { xpArray: [1] };
	const levelsAvailable = props.playerAssets
		? props.playerAssets.levels
		: [1];
	const playerLevelingArray = props.playerAssets
		? props.playerAssets.playerLevelingArray
		: [1];

	const classes = useStyles();
	const newPlayer = props.player
		? props.player
		: defaultPlayer('Protector', 1, {
				baseAssets: props.baseAssets,
				playerAssets: props.playerAssets,
		  });

	const getStatusEffects = (sList) => {
		props.updatePlayerList(
			{ ...newPlayer, Status_Effects: sList },
			props.playerIndex
		);
	};

	const _valueCalc = (val, level) => {
		let output = val;
		for (let idx = 0; idx < level; idx++) {
			output = Math.ceil(output * playerLevelingArray[idx]); //always rounds up
		}
		return output;
	};
	
	return (
		<Dialog
			classes={{ paper: classes.dialogPaperTwo }}
			fullWidth
			maxWidth={'md'}
			open={props.open}
			onClose={props.hidePlayerDisplay}
		>
			<DialogContent>
				<Grid container direction='row' justifyContent='space-between'>
					<Grid item>
						<Typography style={{ fontSize: '1.5rem', color:newPlayer['Color'], cursor:'pointer' }} onClick={handleShowColor} >
							{newPlayer['Player_Name']}: {newPlayer['Spec']}
						</Typography>
						<Dialog 
							open={showColor}
							onClose={handleHideColor}
						>
							<DialogContent>
								<PlayerColorPicker player={newPlayer} playerIndex={props.playerIndex} updatePlayerList={props.updatePlayerList} setShowColor={setShowColor}/>
							</DialogContent>
						</Dialog>
					</Grid>
					<Grid item>
						<Typography style={{ fontSize: '1.5rem' }}>
							{newPlayer['Class']}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
						>
							<Grid item>
								<Typography style={{ fontSize: '1.5rem' }}>
									Level:{' '}
								</Typography>
							</Grid>
							<Grid item>
								<Select
									style={{
										width: 70,
										textAlign: 'center',
										fontSize: '1.5rem',
									}}
									value={newPlayer['Level']}
									onChange={(evt) =>
										props.updatePlayerList(
											{
												...newPlayer,
												Level: evt.target.value,
												HP: _valueCalc(
													newPlayer['Base_HP'],
													evt.target.value
												),
												Req_XP: XP['xpArray'][
													evt.target.value - 1
												],
												Power: _valueCalc(
													newPlayer['Base_Power'],
													evt.target.value
												),
												Crit_Rating: _valueCalc(
													newPlayer[
														'Base_Crit_Rating'
													],
													evt.target.value
												),
												['Ability1']: {
													...newPlayer['Ability1'],
													['Damage']: _valueCalc(
														newPlayer['Ability1'][
															'Base_Damage'
														],
														evt.target.value
													),
													['Healing']: _valueCalc(
														newPlayer['Ability1'][
															'Base_Healing'
														],
														evt.target.value
													),
												},
												['Ability2']: {
													...newPlayer['Ability2'],
													['Damage']: _valueCalc(
														newPlayer['Ability2'][
															'Base_Damage'
														],
														evt.target.value
													),
													['Healing']: _valueCalc(
														newPlayer['Ability2'][
															'Base_Healing'
														],
														evt.target.value
													),
												},
												['Ability3']: {
													...newPlayer['Ability3'],
													['Damage']: _valueCalc(
														newPlayer['Ability3'][
															'Base_Damage'
														],
														evt.target.value
													),
													['Healing']: _valueCalc(
														newPlayer['Ability3'][
															'Base_Healing'
														],
														evt.target.value
													),
												},
												['Ability4']: {
													...newPlayer['Ability4'],
													['Damage']: _valueCalc(
														newPlayer['Ability4'][
															'Base_Damage'
														],
														evt.target.value
													),
													['Healing']: _valueCalc(
														newPlayer['Ability4'][
															'Base_Healing'
														],
														evt.target.value
													),
												},
											},
											props.playerIndex
										)
									}
								>
									{levelsAvailable.map((level, index) => (
										<MenuItem key={index} value={level}>
											{level}
										</MenuItem>
									))}
								</Select>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<PlayerInfo
					player={newPlayer}
					playerIndex={props.playerIndex}
					getStatusEffects={getStatusEffects}
					updatePlayerList={props.updatePlayerList}
					miscAssets={props.miscAssets}
					getCritPercentage={getCritPercentage}
					critPercentage={Math.ceil(critPcnt * 100) / 100}
				/>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignContent='center'
				>
					<Grid item>
						<Grid
							container
							direction='column'
							justifyContent='center'
						>
							<PlayerAbilities
								diceResult={diceResult}
								player={newPlayer}
								enemyList={props.enemyList}
								playerIndex={props.playerIndex}
								updatePlayerList={props.updatePlayerList}
								updateEnemyList={props.updateEnemyList}
								miscAssets={props.miscAssets}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container direction='row' justifyContent='space-between'>
					<Grid item>
						<Button
							onClick={() => {
								props.retirePlayer(props.playerIndex);
								props.hidePlayerDisplay();
							}}
						>
							{' '}
							Retire
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={() => props.hidePlayerDisplay()}>
							CLOSE
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default PlayerDisplay;
