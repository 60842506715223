import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@material-ui/core/';

import EnemyInfo from './EnemyInfo';
import EnemyAbilities from './EnemyAbilities';

const defaultEnemy = require('./defaultEnemy');

const useStyles = makeStyles((theme) => ({
	dialogPaperThree: {
		backgroundColor: 'white',
		height: '675px',
	},
}));

export default function EnemyDisplay(props) {
	
	const classes = useStyles();
	const newEnemy = props.enemy
		? props.enemy
		: defaultEnemy('Ogre', 1, { enemyAssets: props.enemyAssets });

	const getStatusEffects = (sList) => {
		props.updateEnemyList(
			{ ...newEnemy, Status_Effects: sList },
			props.enemyIndex
		);
	};
	// console.log('Enemy Display index: '+ props.index)
	console.log(`${newEnemy['Name']} #${newEnemy['Number']} ROLL: ` + props.enemyRolls[props.index]);

	return (
		<Dialog
			classes={{ paper: classes.dialogPaperThree }}
			fullWidth
			maxWidth={'md'}
			open={props.open}
			onClose={props.hideEnemyDisplay}
		>
			<DialogContent>
				<Grid container direction='row' justifyContent='space-between'>
					<Grid item>
						<Typography style={{ fontSize: '1.5rem' }}>
							{newEnemy['Name']} {`#${newEnemy['Number']}`}
						</Typography>
					</Grid>
					<Grid item>
						<Typography style={{ fontSize: '1.5rem' }}>
							{newEnemy['Rank']}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
						>
							<Grid item>
								<Typography style={{ fontSize: '1.5rem' }}>
									{`Level:  `}&nbsp;
								</Typography>
							</Grid>
							<Grid item>
								<Typography style={{ fontSize: '1.5rem' }}>
									{newEnemy['Level']}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<EnemyInfo
					miscAssets={props.miscAssets}
					enemy={newEnemy}
					enemyIndex={props.enemyIndex}
					getStatusEffects={getStatusEffects}
					updateEnemyList={props.updateEnemyList}
				/>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignContent='center'
				>
					<Grid item>
						<Grid
							container
							direction='column'
							justifyContent='center'
						>
							<EnemyAbilities
								enemy={newEnemy}
								enemyIndex={props.enemyIndex}
								updateEnemyList={props.updateEnemyList}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container direction='row' justifyContent='space-between'>
					<Grid item>
						<Button
							onClick={() => {
								props.vanquishEnemy(props.enemyIndex);
								props.hideEnemyDisplay();
							}}
						>
							Vanquish
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={() => props.hideEnemyDisplay()}>
							CLOSE
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
