import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Dice from './dice';
import { Typography } from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
	const [open, setOpen] = useState(false);
	const [time, setTime] = useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleGetTime = (time) => {
		return setTime(time);
	};

	return (
		<div>
			<Button
				variant='outlined'
				onClick={handleClickOpen}
				style={{
					width: '5rem',
					color: 'white',
					backgroundColor: '#702963',
					padding: '8px 6px'
				}}
			>
				Dice
			</Button>
			<Dialog
                fullWidth
                maxWidth={'sm'}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle style={{textAlign:'center'}}>Select and roll a die!</DialogTitle>
				<DialogContent>
					<Dice handleGetTime={handleGetTime} enemyAssets={props.enemyAssets} />
					<Typography>Roll Time: {time}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>CLOSE</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
