import React from 'react';
import { GithubPicker  } from 'react-color';

export default function ColorPicker(props) {
	const handleChangeComplete = (color) => {
        props.updatePlayerList({...props.player,Color: color.hex},props.playerIndex);
        props.setShowColor(false);
	};

	return (
		<GithubPicker
            triangle='hide'
            colors={['#000000', '#808080', '#B80000', '#CCCC00', '#0000FF','#008000','#800080','#00FFFF']}
			color={props.player['Color']}
			onChangeComplete={handleChangeComplete}
		/>
	);
};
