import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { FormControl, Grid, Select } from '@mui/material';

const defaultPlayer = require('./defaultPlayer.js');

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const PlayerButton = (props) => {
	const [open, setOpen] = useState(false);
	// const [player, setPlayer] = useState();
	const [player, setPlayer] = useState(defaultPlayer('Protector',1,{baseAssets: props.baseAssets,playerAssets: props.playerAssets}));
	const PlayerAssets = props.playerAssets;
	const playerClassSpecs = PlayerAssets.Specs;
	const levelsAvailable = PlayerAssets.levels;
	
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setOpen(false);
		setTimeout(() => {
			props.getPlayerList(player);
		}, 500);
	};

	return (
		<>
			<Button
				onClick={handleClickOpen}
				style={{
					width: '5rem',
					backgroundColor: '#85A1F2',
				}}
			>
				Player
			</Button>
			<Dialog
				fullWidth
				maxWidth={'lg'}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<DialogTitle style={{ textAlign: 'center' }}>
					New Player Data
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction='row'
						alignItems='flex-end'
						justifyContent='space-between'
					>
						<Grid item>
							<Grid container direction='column'>
								<Grid item>
									<FormControl fullWidth>
										<InputLabel
											variant='standard'
											htmlFor='uncontrolled-native'
											style={{ fontWeight: '600' }}
										>
											Spec
										</InputLabel>
										<Select
											style={{ width: 200 }}
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											value={player.Spec}
											label='Spec'
											onChange={(evt) =>
												setPlayer({
													...player,
													...defaultPlayer(
														evt.target.value,
														1,
														{baseAssets: props.baseAssets,playerAssets: props.playerAssets}
													),
												})
											}
										>
											{playerClassSpecs.map(
												(Spec, index) => (
													<MenuItem
														key={index}
														value={Spec}
													>
														{Spec}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<FormControl fullWidth>
								<InputLabel
									variant='standard'
									htmlFor='uncontrolled-native'
									style={{ fontWeight: '600' }}
								>
									Level
								</InputLabel>
								<Select
									style={{ width: 80 }}
									value={player.Level}
									label='Level'
									onChange={(evt) =>
										setPlayer({
											...player,
											...defaultPlayer(
												player.Spec,
												evt.target.value,
												{baseAssets: props.baseAssets,playerAssets: props.playerAssets}
											),
										})
									}
								>
									{levelsAvailable.map((level, index) => (
										<MenuItem key={index} value={level}>
											{level}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<InputLabel
								variant='standard'
								htmlFor='uncontrolled-native'
								style={{ fontSize: '1rem', fontWeight: '600' }}
							>
								HP
							</InputLabel>
							<TextField
								id='standard-number'
								//
								type='number'
								InputLabelProps={{
									shrink: true,
								}}
								variant='standard'
								style={{
									width: 90,
									textAlign: 'right',
									fontWeight: '600',
								}}
								value={player.HP}
								onChange={(evt) => {
									setPlayer({
										...player,
										HP: evt.target.value,
									});
								}}
							/>
						</Grid>
						<Grid item>
							<Grid
								container
								direction='column'
								justifyContent='center'
							>
								<Grid item>
									<InputLabel
										variant='standard'
										htmlFor='uncontrolled-native'
										style={{
											fontSize: '1rem',
											fontWeight: '600',
										}}
									>
										Name
									</InputLabel>
									<TextField
										size='small'
										style={{
											fontSize: '1rem',
											width: '26rem',
										}}
										value={player.Player_Name}
										onChange={(evt) => {
											setPlayer({
												...player,
												Player_Name: evt.target.value,
											});
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>CLOSE</Button>
					<Button disabled={!player.Player_Name} onClick={handleSave}>
						ENLIST
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};



export default PlayerButton;
