import React from 'react';
import { Select } from '@material-ui/core/';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function GearSelect(props) {
	const gearRows = Object.keys(props.mainHand);
	return (
		<Select
			style={{ width: 200, textAlign: 'center', fontSize: '0.75rem', height: 25, overflow: 'hidden' }}
			label='Name'
			input={<OutlinedInput label='Name' />}
			value={props.chosen ? props.chosen :  'None'}
			onChange={(evt) => {
				props.getSelectedGear('Main_Hand',evt.target.value);
			}}
		>
			<MenuItem disabled value=''>
				<em>-Main Hand-</em>
			</MenuItem>
			<MenuItem value='None'>
				<em>None</em>
			</MenuItem>
			{gearRows.map((gear, index) => (
				<MenuItem key={index} value={gear}>
					{gear}
				</MenuItem>
			))}
		</Select>
	);
}
