import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from '../../assets/images/axe.png';
import Fader from '../../components/Fader/Fader';

import './Welcome.css';

const styles = () => ({
	title: {
		padding: '2rem 2rem',
		margin: '5px',
		textAlign: 'center',
		color: '#ffffff',
		backgroundColor: '#3d3d3d',
	},
});

class Welcome extends Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}
	
	componentDidMount() {
		this.props.getAssetData();
	}

	loadGame = (stuff) => {
		fetch(process.env.REACT_APP_ASSET_URL + '/load_game', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(stuff)})
			.then((resp) => resp.json())
			.then((data) => {
				// console.log(data);
			});
	};

	deleteGame = (stuff) => {
		fetch(process.env.REACT_APP_ASSET_URL + '/delete_game', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(stuff)})
			.then((resp) => resp.json())
			.then((data) => {
				// console.log(data);
			});
	};

	render() {
		const { classes,base,enemy,misc,player } = this.props;
		
		
		return (
			<div className={classes.title}>
				<Typography variant='h4'>
					Welcome to your KoT testing tools!
				</Typography>
				<img
					style={{ padding: '4rem 4rem' }}
					src={logo}
					className='Welcome-logo'
					alt='logo'
				/>
				<br />
				{/* <Button variant='outlined' onClick={() =>this.loadGame({cheese: 'delicious'})}>load</Button>
				<Button variant='outlined' onClick={() =>this.deleteGame({cheese: 'delicious'})}>delete</Button> */}
				<br/>
				<Fader text={player === false || base === false  || misc === false  || enemy === false  ? '...Loading Assets...': 'Combat Simulator Link'} />
				
			</div>
		);
	}
}

export default withStyles(styles)(Welcome);
