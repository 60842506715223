const defaultEnemy = (name='Ogre', level = 1,props) => {
	const EnemyAssetData = props.enemyAssets;
	const DataObj = EnemyAssetData['enemyClassNames']['EnemyObjects'].find(item => {
		if(item.Name === name) {
			return item
		}
	});
	
	const levelingArray = EnemyAssetData.enemyLevelingArray;
	

const _valueCalc = (val,level) => {
	let output = val; 
	for (let idx = 0; idx < level; idx++) {
		output = Math.ceil(output*levelingArray[idx]) //always rounds up
	}
	return output;
};
	return {
		Race: DataObj.Race,
		Name: DataObj.Name,
		Number: 1,
		Class: DataObj.Class,
		Rank: DataObj.Rank,
        Level: level,
        Status_Effects: [],
		Base_HP: _valueCalc(DataObj.HP,level),
		HP: _valueCalc(DataObj.HP,level),
        Armor: DataObj.Armor,
        Movement: DataObj.Movement,
        Range: DataObj.Range,
        Attack_Power: _valueCalc(DataObj.AttackPower,level),
        Spell_Power: _valueCalc(DataObj.SpellPower,level),
		Ability_1: {
			Name: DataObj['Ability_1']['Name'],
			Function: DataObj['Ability_1']['Function'],
			Notes: DataObj['Ability_1']['Notes'],
			Duration: DataObj['Ability_1']['Duration'],
			Control: DataObj['Ability_1']['Control'],
			Damage: _valueCalc(DataObj['Ability_1']['Damage'],level),
			Healing: _valueCalc(DataObj['Ability_1']['Healing'],level),
			Range: DataObj['Ability_1']['Range'],
		},
        Ability_2: {
			Name: DataObj['Ability_2']['Name'],
			Function: DataObj['Ability_2']['Function'],
			Notes: DataObj['Ability_2']['Notes'],
			Duration: DataObj['Ability_2']['Duration'],
			Control: DataObj['Ability_2']['Control'],
			Damage: _valueCalc(DataObj['Ability_2']['Damage'],level),
			Healing: _valueCalc(DataObj['Ability_2']['Healing'],level),
			Range: DataObj['Ability_2']['Range'],
		},
        Ability_3: {
			Name: DataObj['Ability_3']['Name'],
			Function: DataObj['Ability_3']['Function'],
			Notes: DataObj['Ability_3']['Notes'],
			Duration: DataObj['Ability_3']['Duration'],
			Control: DataObj['Ability_3']['Control'],
			Damage: _valueCalc(DataObj['Ability_3']['Damage'],level),
			Healing: _valueCalc(DataObj['Ability_3']['Healing'],level),
			Range: DataObj['Ability_3']['Range'],
		},
        Ability_4: {
			Name: DataObj['Ability_4']['Name'],
			Function: DataObj['Ability_4']['Function'],
			Notes: DataObj['Ability_4']['Notes'],
			Duration: DataObj['Ability_4']['Duration'],
			Control: DataObj['Ability_4']['Control'],
			Damage: _valueCalc(DataObj['Ability_4']['Damage'],level),
			Healing: _valueCalc(DataObj['Ability_4']['Healing'],level),
			Range: DataObj['Ability_4']['Range'],
		}
        // Ability_5: {
		// 	Name: DataObj['Ability_5']['Name'],
		// 	Function: DataObj['Ability_5']['Function'],
		// 	Notes: DataObj['Ability_5']['Notes'],
		// 	Duration: DataObj['Ability_5']['Duration'],
		// 	Control: DataObj['Ability_5']['Control'],
		// 	Damage: _valueCalc(DataObj['Ability_5']['Damage'],level),
		// 	Healing: _valueCalc(DataObj['Ability_5']['Healing'],level),
		// 	Range: DataObj['Ability_5']['Range'],
		// }
	};
};

module.exports = defaultEnemy;
