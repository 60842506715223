import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularSpinner from '../../assets/spinners/circularSpinner';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function DiceFunction(dice) {
	let roll = '';
	switch (dice) {
		default:
			break;
		case 'D4':
			roll = Math.floor(Math.random() * 4) + 1;
			break;
		case 'D6':
			roll = Math.floor(Math.random() * 6) + 1;
			break;
		case 'D8':
			roll = Math.floor(Math.random() * 8) + 1;
			break;
		case 'D10':
			roll = Math.floor(Math.random() * 10) + 1;
			break;
		case 'D12':
			roll = Math.floor(Math.random() * 12) + 1;
			break;
		case 'D20':
			roll = Math.floor(Math.random() * 20) + 1;
			break;
		case 'Percentile':
			roll = Math.floor(Math.random() * 100) + 1;
			break;
	}
    return roll;
}

export default function DiceSelect(props) {
	const combatProbsD20 = props.enemyAssets.combatProbsD20;
	const [selectedDice, setSelectedDice] = useState('D20');
	const [rollResult, setRollResult] = useState('');
	const [rolling, setRolling] = useState(false);
	const [open, setOpen] = useState(false);

	const handleClickOpenEnemy = () => {
		setOpen(true);
	};

	const handleCloseEnemy = () => {
		setOpen(false);
	};

	async function getDiceRoll() {
		setRollResult(DiceFunction(selectedDice));
	}

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedDice(value);
	};

	const handleRolling = () => {
		setTimeout(() => {
			setRolling(false);
		}, 500);
	};

	const handleRollResult = () => {
		return rolling ? (
			<CircularSpinner />
		) : (
			<Typography style={{ color: '#B80000', fontSize: '2em' }}>
				{rollResult}
			</Typography>
		);
	};

	const handleTimeRoll = () => {
		const today = new Date();
		props.handleGetTime(today.toLocaleTimeString());
	};

	return (
		<>
			<Grid
				container
				direction='row'
				alignItems='center'
				alignContent='center'
				spacing={3}
			>
				<Grid item>
					<ToggleButtonGroup
						defaultValue={'D20'}
						value={selectedDice}
						exclusive
						onChange={handleChange}
						aria-label='text alignment'
						size='large'
					>
						<ToggleButton value='D4'>D4</ToggleButton>
						<ToggleButton value='D6'>D6</ToggleButton>
						<ToggleButton value='D8'>D8</ToggleButton>
						<ToggleButton value='D10'>D10</ToggleButton>
						<ToggleButton value='D12'>D12</ToggleButton>
						<ToggleButton value='D20'>D20</ToggleButton>
						<ToggleButton value='Percentile'>
							&nbsp;%&nbsp;
						</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
				<Grid item>
					<Typography variant='outlined'>
						{handleRollResult()}
					</Typography>
				</Grid>
			</Grid>
			<Grid item>
				<Button
					disabled={rolling || selectedDice === ''}
					size='large'
					style={{
						width: '13.5em',
						color: 'white',
						backgroundColor:
							selectedDice === '' ? '#ccc' : '#000084',
					}}
					variant='outlined'
					onClick={() => {
						getDiceRoll(selectedDice);
						setRolling(true);
						handleRolling();
						handleTimeRoll();
					}}
				>
					ROLL
				</Button>
				<Button
					disabled={!rollResult || selectedDice !== 'D20'}
					onClick={handleClickOpenEnemy}
					size='large'
					style={{
						width: '13.5em',
						color: 'white',
						backgroundColor:
							selectedDice === '' ? '#ccc' : '#CD5E77',
					}}
					variant='outlined'
				>
					Enemy Roll
				</Button>
				<Dialog maxWidth={'xl'} open={open} onClose={handleCloseEnemy}>
					<DialogTitle style={{ width: '10em', textAlign: 'center' }}>
						Enemy Roll Ability
					</DialogTitle>
					<DialogContent>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
						>
							<Grid item>
								<Typography>Minion Result:</Typography>
							</Grid>

							<Grid item>
								<Typography>
									{
										combatProbsD20.Minion.rollArray[
											parseInt(rollResult) - 1
										]
									}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
						>
							<Grid item>
								<Typography>Elite Result:</Typography>
							</Grid>

							<Grid item>
								<Typography>
									{
										combatProbsD20.Elite.rollArray[
											parseInt(rollResult) - 1
										]
									}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
						>
							<Grid item>
								<Typography>Boss Result:</Typography>
							</Grid>

							<Grid item>
								<Typography>
									{
										combatProbsD20.Boss.rollArray[
											parseInt(rollResult) - 1
										]
									}
								</Typography>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseEnemy}>CLOSE</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</>
	);
}
