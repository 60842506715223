import React, { useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MainHandSelect from './SelectGear/MainHandSelect';
import OffHandSelect from './SelectGear/OffHandSelect';
import TwoHandedSelect from './SelectGear/TwoHandedSelect';

const defaultPlayer = require('./defaultPlayer');

export default function PlayerWeapons(props) {
	const [selectedGear,setSelectedGear] = useState('None');
  const updatePlayer = props.player ? props.player : defaultPlayer('Protector',1,{baseAssets: props.baseAssets,playerAssets: props.playerAssets});
  
  const Gear = props.weapons;

  const getSelectedGear = (slot,selected) => {
    setSelectedGear(selected);
    if (selected !== 'None') {
      props.updatePlayerList(
        {
          ...updatePlayer,
          Gear: {
            ...updatePlayer['Gear'],
            [slot]: {
              Name: selected,
              Armor_Type: Gear[slot][selected]['Armor_Type'],
              Armor: Gear[slot][selected]['Armor'],
              Vitality: Gear[slot][selected]['Vitality'],
              Efficiency: Gear[slot][selected]['Efficiency'],
              Regen: Gear[slot][selected]['Regen'],
              Critical_Strike:
                Gear[slot][selected]['Critical_Strike'],
            },
          },
        },
        props.playerIndex
      );
    } else {
      props.updatePlayerList(
        {
          ...updatePlayer,
          Gear: {
            ...updatePlayer['Gear'],
            [slot]: {
              Name: '',
              Armor_Type: '',
              Armor: 0,
              Vitality: 0,
              Efficiency: 0,
              Regen: 0,
              Critical_Strike: 0,
            },
          },
        },
        props.playerIndex
      );
    }
  };
  
  return (
    <TableContainer component={Paper} style={{justifyContent: 'center',backgroundColor: 'white'}}>
      <Table style={{ minWidth: 550 }} size="small" >
        <TableHead>
        <TableRow key={'columns'} style={{ fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
		  	    <TableCell align='left' style={{fontWeight: 'bold'}}>Slot</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}}>Name</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}}>Armor</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}}>Vitality</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}}>Efficiency</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}}>Regen</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}}>Critical Strike</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow key={'Main_Hand'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
          <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Main Hand</TableCell>
            <TableCell align="center" style={{fontSize: '0.6rem'}}><MainHandSelect mainHand={props.weapons.Main_Hand} chosen={updatePlayer['Gear']['Main_Hand']['Name']} getSelectedGear={getSelectedGear} /></TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Main_Hand']['Armor']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Main_Hand']['Vitality']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Main_Hand']['Efficiency']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Main_Hand']['Regen']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Main_Hand']['Critical_Strike']}</TableCell>
          </TableRow>
          <TableRow key={'Off_Hand'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
          <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Off Hand</TableCell>
            <TableCell align="center" style={{fontSize: '0.6rem'}}><OffHandSelect offHand={props.weapons.Off_Hand} chosen={updatePlayer['Gear']['Off_Hand']['Name']} getSelectedGear={getSelectedGear} /></TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Off_Hand']['Armor']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Off_Hand']['Vitality']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Off_Hand']['Efficiency']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Off_Hand']['Regen']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Off_Hand']['Critical_Strike']}</TableCell>
          </TableRow>
          <TableRow key={'Two_Handed'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
          <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Two Handed</TableCell>
            <TableCell align="center" style={{fontSize: '0.6rem'}}><TwoHandedSelect twoHanded={props.weapons.Two_Handed} chosen={updatePlayer['Gear']['Two_Handed']['Name']} getSelectedGear={getSelectedGear} /></TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Two_Handed']['Armor']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Two_Handed']['Vitality']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Two_Handed']['Efficiency']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Two_Handed']['Regen']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Two_Handed']['Critical_Strike']}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}