import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: '5rem',
		},
	},
};

function getStyles(status, StatusEffects, theme) {
	return {
		fontWeight:
			StatusEffects.indexOf(status) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function StatusEffect(props) {
	const StatusEffects = props.miscAssets.StatusEffects;
	const statusesDebuffs = Object.keys(StatusEffects['Debuffs']).sort();
	const statusesBuffs = Object.keys(StatusEffects['Buffs']).sort();
	const allStatuses = {
		...StatusEffects['Debuffs'],
		...StatusEffects['Buffs'],
	};
	const theme = useTheme();
	const [statusEffects, setStatusEffects] = React.useState([]);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		props.getStatusEffects(
			typeof value === 'string' ? value.split(',') : value
		);
		setStatusEffects(typeof value === 'string' ? value.split(',') : value);
	};

	return (
		<div>
			<FormControl
				style={{
					padding: '0.5rem 0',
					margin: '1rem 0rem 0rem 0rem',
					width: '26rem',
					height: '4rem',
				}}
			>
				<InputLabel style={{ alignItems: 'center' }}>
					Status Effects
				</InputLabel>
				<Select
					style={{ height: '4rem' }}
					multiple
					value={props.player['Status_Effects']}
					onChange={handleChange}
					input={<OutlinedInput label='Status Effects' />}
					renderValue={(selected) => (
						<Box
							sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
						>
							{selected.map((value) => (
								<Tooltip
									key={value}
									title={allStatuses[value]['Description']}
									arrow
									placement='bottom'
								>
									<Chip key={value} label={value} />
								</Tooltip>
							))}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					<MenuItem disabled value=''>
						<em>-Debuffs-</em>
					</MenuItem>
					{statusesDebuffs.map((stats) => (
						<MenuItem
							key={stats}
							value={stats}
							style={getStyles(stats, statusEffects, theme)}
						>
							{stats}
						</MenuItem>
					))}
					<MenuItem disabled value=''>
						<em>-Buffs-</em>
					</MenuItem>
					{statusesBuffs.map((stats) => (
						<MenuItem
							key={stats + 'asdf'}
							value={stats}
							style={getStyles(stats, statusEffects, theme)}
						>
							{stats}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
