import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core/';
import CardActionArea from '@mui/material/CardActionArea';
import Icon from '@material-ui/core/Icon';
import Bolt from '../../assets/images/bolt.svg';
import Heart from '../../assets/images/heart.svg';
import Skull from '../../assets/images/skull.svg';

export default function PlayerList(props) {
	return (
		<div >
			<Grid
				container
				direction='row'
				alignContent='center'
				justifyContent='center'
			>
				<Grid item xs={6}>
					<Grid container direction='column' alignItems='center' alignContent='center' >
						<Typography variant='h4' style={{padding: '5px 5px'}}>Party</Typography>
						{props.playerList.map((elem, index) => (
							<Grid item key={index}>
								<Card style={{ width: '26rem', backgroundColor: 'white',margin:'2px'	}} >
									<CardActionArea onClick={() => {props.getPlayerIndex(index);}} >
										<CardContent>
											<Grid container direction='row' justifyContent='space-between' alignItems='baseline'>
												<Grid item >
													<Typography	variant='h5' style={{ textAlign: 'left',color: elem.Color,fontWeight: 'bold' }} >
														{elem.Player_Name}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='h5' style={{ color: '#CCCC00',	alignContent: 'center'}} >
														<Icon ><img src={Bolt} alt='bolt'/></Icon> {elem.Energy}
													</Typography>
												</Grid>
												<Grid item>
													<Typography	variant='h5'style={{color: '#B399D4', alignContent:'center'}}>
														<Icon ><img color='#B399D4' src={Skull} alt='skull'/></Icon>{elem.Threat}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='h5' style={{ color: '#B80000',	 alignContent: 'center'}} >
														<Icon ><img src={Heart} alt='heart'/></Icon> {elem.HP}/{elem.Base_HP}
													</Typography>
												</Grid>
											</Grid>
											<Grid container direction='row' style={{padding: '2px'}}>
												<Grid item>
													<Typography >
													{elem.Status_Effects.toString()}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
