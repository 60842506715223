import React from 'react';
import StatusEffects from './StatusEffects';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@material-ui/core/';
import ArrowUp from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowDown from '@mui/icons-material/ArrowCircleDownTwoTone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from '@material-ui/core/Icon';
import Bolt from '../../assets/images/bolt.svg';
import Heart from '../../assets/images/heart.svg';
import Skull from '../../assets/images/skull.svg';

const defaultPlayer = require('./defaultPlayer');
export default function PlayerInfo(props) {
	const newPlayer = props.player
		? props.player
		: defaultPlayer('Protector', 1, {
				baseAssets: props.baseAssets,
				playerAssets: props.playerAssets,
		  });

	
	const _critPcntCalc = (crit_sums, level) => {
		const result = 0.02 + crit_sums / level / 100;
		return result * 100;
	};

	props.getCritPercentage({
		crit_pcnt: parseFloat(
			_critPcntCalc(newPlayer['Total_Critical_Rating'], newPlayer['Level']) / 100
		).toFixed(3),
		criticalStrikeStatSums: newPlayer['Total_Critical_Rating'],
	});

	return (
		<Grid container direction='column' style={{ margin: 0 }}>
			<Grid item>
				<Grid
					container
					direction='row'
					justifyContent='center'
					spacing={2}
				>
					<Grid item xs={8}>
						<Box
							style={{
								padding: '0.5rem',
								border: '1px solid black',
							}}
						>
							<TableContainer
								component={Paper}
								style={{
									justifyContent: 'space-between',
									backgroundColor: 'white',
								}}
							>
								<Table size='small'>
									<TableBody>
										<TableRow>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Power:
											</TableCell>
											<TableCell>
												{newPlayer['Power']}
											</TableCell>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Vitality:
											</TableCell>
											<TableCell>
												{props.player['Total_Vitality']}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Movement:
											</TableCell>
											<TableCell>
												{newPlayer['Movement']}
											</TableCell>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Efficiency
											</TableCell>
											<TableCell>
												{
													props.player[
														'Total_Efficiency'
													]
												}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Crit Strike Rating:
											</TableCell>
											<TableCell>
												{newPlayer['Total_Critical_Rating']}
											</TableCell>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Regeneration
											</TableCell>
											<TableCell>
												{newPlayer['Total_Regen']}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Crit Strike Chance:
											</TableCell>
											<TableCell>
												{`${parseFloat(
													Math.ceil(
														_critPcntCalc(
															newPlayer[
																'Total_Critical_Rating'
															],
															newPlayer['Level']
														).toFixed(3)
													)
												)}%`}
											</TableCell>
											<TableCell
												style={{ fontWeight: 'bold' }}
											>
												Miss Chance:
											</TableCell>
											<TableCell>
												{newPlayer['Miss_Chance'] * 100}
												%
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Grid item>
							<Grid
								container
								direction='row'
								justifyContent='flex-start'
							>
								<Grid item>
									<StatusEffects
										miscAssets={props.miscAssets}
										player={newPlayer}
										getStatusEffects={
											props.getStatusEffects
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4} style={{ margin: '1rem 0rem 0rem 0rem' }}>
						<Grid
							container
							direction='column'
							justifyContent='flex-start'
							alignContent='center'
						>
							<Grid item>
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='space-between'
								>
									<Grid item>
										<Typography>XP:</Typography>
									</Grid>
									<Grid item>
										<ArrowUp
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#77DD77',
											}}
											onClick={() =>
												newPlayer['XP'] <
												newPlayer['Req_XP']
													? props.updatePlayerList(
															{
																...newPlayer,
																XP:
																	newPlayer[
																		'XP'
																	] + 1,
															},
															props.playerIndex
													  )
													: null
											}
										/>
									</Grid>
									<Grid item>
										<Typography>
											&nbsp;{newPlayer['XP']}&nbsp;
										</Typography>
									</Grid>
									<Grid item>
										<ArrowDown
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#F08B8B',
											}}
											onClick={() =>
												newPlayer['XP'] > 0
													? props.updatePlayerList(
															{
																...newPlayer,
																XP:
																	newPlayer[
																		'XP'
																	] - 1,
															},
															props.playerIndex
													  )
													: null
											}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='space-between'
								>
									<Grid item>
									<Typography><Icon><img src={Heart} alt='heart'/></Icon></Typography>
									</Grid>
									<Grid item>
										<ArrowUp
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#77DD77',
											}}
											onClick={() =>
												props.updatePlayerList(
													{
														...newPlayer,
														HP: newPlayer['HP'] + 1,
													},
													props.playerIndex
												)
											}
										/>
									</Grid>
									<Grid item>
										<Typography>
											{newPlayer['HP']}
										</Typography>
									</Grid>
									<Grid item>
										<ArrowDown
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#F08B8B',
											}}
											onClick={() =>
												newPlayer['HP'] > 0
													? props.updatePlayerList(
															{
																...newPlayer,
																HP:
																	newPlayer[
																		'HP'
																	] - 1,
															},
															props.playerIndex
													  )
													: null
											}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='space-between'
								>
									<Grid item>
									<Typography><Icon><img src={Bolt} alt='bolt'/></Icon></Typography>
									</Grid>
									<Grid item>
										<ArrowUp
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#77DD77',
											}}
											onClick={() =>
												props.updatePlayerList(
													{
														...newPlayer,
														Energy:
															newPlayer[
																'Energy'
															] + 1,
													},
													props.playerIndex
												)
											}
										/>
									</Grid>
									<Grid item>
										<Typography>
											&nbsp;{newPlayer['Energy']}&nbsp;
										</Typography>
									</Grid>
									<Grid item>
										<ArrowDown
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#F08B8B',
											}}
											onClick={() =>
												newPlayer['Energy'] > 0
													? props.updatePlayerList(
															{
																...newPlayer,
																Energy:
																	newPlayer[
																		'Energy'
																	] - 1,
															},
															props.playerIndex
													  )
													: null
											}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='space-between'
								>
									<Grid item>
										<Typography><Icon><img src={Skull} alt='skull'/></Icon></Typography>
									</Grid>
									<Grid item>
										<ArrowUp
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#77DD77',
											}}
											onClick={() =>
												newPlayer['Threat'] <
												newPlayer['Base_Threat'] + 1000
													? props.updatePlayerList(
															{
																...newPlayer,
																Threat:
																	newPlayer[
																		'Threat'
																	] + 1,
															},
															props.playerIndex
													  )
													: null
											}
										/>
									</Grid>
									<Grid item>
										<Typography>
											&nbsp;{newPlayer['Threat']}&nbsp;
										</Typography>
									</Grid>
									<Grid item>
										<ArrowDown
											style={{
												cursor: 'pointer',
												fontSize: '3rem',
												color: '#F08B8B',
											}}
											onClick={() =>
												newPlayer['Threat'] > 0
													? props.updatePlayerList(
															{
																...newPlayer,
																Threat:
																	newPlayer[
																		'Threat'
																	] - 1,
															},
															props.playerIndex
													  )
													: null
											}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
