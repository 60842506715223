import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core/';
import CardActionArea from '@mui/material/CardActionArea';
import Icon from '@material-ui/core/Icon';
import Heart from '../../assets/images/heart.svg';

export default function EnemyList(props) {
	
	return (
		<div >
			<Grid
				container
				direction='row'
				alignContent='center'
				justifyContent='center'
			>
				<Grid item xs={6}>
					<Grid container direction='column' alignItems='center' alignContent='center' >
					<Typography variant='h4' style={{padding: '5px 5px'}}>Foes</Typography>
						{props.enemyList.map((elem, index) => (
							<Grid item key={index}>
								<Card style={{ width: '26rem', backgroundColor: 'white', margin: '2px'	}} >
									<CardActionArea onClick={() => {props.getEnemyIndex(index);}} >
										<CardContent>
											<Grid container direction='row' justifyContent='space-between'>
												<Grid item>
													<Typography	variant='h5' style={{ textAlign: 'left'	}} >
														{elem.Name} {`#${elem.Number}`}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='h5' style={{ color: '#B80000',	 alignContent: 'center'}} >
														<Icon ><img src={Heart} alt='heart'/></Icon> {elem.HP}/{elem.Base_HP}
													</Typography>
												</Grid>
											</Grid>
											<Grid container direction='row' justifyContent='space-between' >
												<Grid item>
													<Typography style={{fontSize: '1rem'}}>
														{elem.Status_Effects.toString()}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
