import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';

const Fader = (props) => {
	return (
		<Fade timeout={4000} in>
			<Typography
				variant='h4'
				component={Link}
				to='/CombatSim'
				style={{
					textDecoration: 'none',
					boxShadow: 'none',
					color: 'white',
					textAlign: 'center',
					
				}}
			>
				{props.text}
			</Typography>
		</Fade>
	);
};

Fader.defaultProps = {
	text: '...Waiting for Data...',
};

Fader.propTypes = {
	text: PropTypes.string,
};

export default Fader;
