import React from 'react';
import { Grid } from '@material-ui/core/';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from "@material-ui/core/Paper";

const defaultEnemy = require('../Enemy/defaultEnemy');

export default function Ability3(props) {
	const newEnemy = props.enemy
		? props.enemy
		: defaultEnemy('Ogre', 1,{enemyAssets: props.enemyAssets});

	return (
		<div>
			<Grid
				container
				direction='column'
				justifyContent='flex-start'
				alignItems='center'
			>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ width: '26rem' }}
				>
					<Grid item>
					<Box style={{ padding: '0.5rem', border: '1px solid black' }}>
					<TableContainer component={Paper}>
						<Table>
							<TableBody>
								<TableRow >
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Damage</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newEnemy['Ability_3']['Damage']}
									</TableCell>
								</TableRow>
								<TableRow >
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Healing</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newEnemy['Ability_3']['Healing']}
									</TableCell>
								</TableRow>
							</TableBody>
							</Table>
							</TableContainer>
						</Box>
					</Grid>
					<Grid item>
					<Box style={{ padding: '0.5rem', border: '1px solid black' }}>
					<TableContainer component={Paper}>
						<Table>
							<TableBody>
								<TableRow >
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Range</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newEnemy['Ability_3']['Range']}
									</TableCell>
								</TableRow>
								<TableRow >
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Control</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newEnemy['Ability_3']['Control']}
									</TableCell>
								</TableRow>
							</TableBody>
							</Table>
							</TableContainer>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
