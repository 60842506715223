import React from 'react';
import StatusEffects from './StatusEffects';
import Box from '@mui/material/Box';
import {  Grid, Typography } from '@material-ui/core/';
import ArrowUp from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowDown from '@mui/icons-material/ArrowCircleDownTwoTone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from '@material-ui/core/Icon';
import Heart from '../../assets/images/heart.svg';

const defaultEnemy = require('../Enemy/defaultEnemy');

export default function EnemyInfo(props) {
    const newEnemy = props.enemy ? props.enemy : defaultEnemy('Ogre',1,{enemyAssets: props.enemyAssets});
    
    return (
        <Grid container direction='column' style={{margin: 0}} >
            <Grid item>
                <Grid container direction='row' justifyContent='center' spacing={2}>
                    <Grid item xs={8}>
                        <Box style={{padding: '0.5rem',border: '1px solid black'}}>
                            <TableContainer component={Paper} style={{justifyContent: 'space-between',backgroundColor: 'white'}}>
                                <Table size="small" >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{fontWeight: 'bold'}} >Armor:</TableCell>
                                            <TableCell>{newEnemy['Armor']}</TableCell>
                                            <TableCell style={{fontWeight: 'bold'}}>Attack Power:</TableCell>
                                            <TableCell>{newEnemy['Attack_Power']}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{fontWeight: 'bold'}}>Movement:</TableCell>
                                            <TableCell>{newEnemy['Movement']}</TableCell>
                                            <TableCell style={{fontWeight: 'bold'}}>Spell Power:</TableCell>
                                            <TableCell>{newEnemy['Spell_Power']}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Grid item >
                            <Grid container direction='row' justifyContent='flex-start'>
                                <Grid item><StatusEffects statusEffects={props.miscAssets.StatusEffects} enemy={newEnemy} getStatusEffects={props.getStatusEffects} /></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} style={{margin: '1rem 0rem 0rem 0rem'}}>
                    <Grid container direction='column' justifyContent='flex-start' alignContent='center'>
                            <Grid item>
                                <Grid container direction='row' alignItems='center' justifyContent='space-between' >
                                    <Grid item >
                                        <Typography><Icon><img src={Heart} alt='heart'/></Icon></Typography>
                                    </Grid>
                                    <Grid item>
                                        <ArrowUp style={{cursor: 'pointer', fontSize: '3rem',color: '#77DD77'}} onClick={() => newEnemy['HP'] < newEnemy['Base_HP'] ? props.updateEnemyList({...newEnemy,'HP': newEnemy['HP'] + 1},props.enemyIndex): null}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography >{newEnemy['HP']}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ArrowDown style={{cursor: 'pointer', fontSize: '3rem',color: '#F08B8B'}} onClick={() => newEnemy['HP']> 0 ? props.updateEnemyList({...newEnemy,'HP': newEnemy['HP'] - 1},props.enemyIndex) : null}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    );
}