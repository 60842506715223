import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { FormControl, Grid, Select } from '@mui/material';

const defaultEnemy = require('./defaultEnemy.js');

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function EnemyButton(props) {
	const [open, setOpen] = useState(false);
	const [enemy, setEnemy] = useState({ ...defaultEnemy('Ogre', 1,{enemyAssets: props.enemyAssets}) });

	const EnemyAssets = props.enemyAssets;
	// const enemyNames = EnemyAssets.enemyClassNames.Names;
	const enemyClassNames = EnemyAssets.enemyClassNames.EnemyObjects;
	const levelsAvailable = EnemyAssets.levels;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setOpen(false);
		setTimeout(() => {
			props.getEnemyList(enemy);
		}, 500);
	};

	return (
		<>
			<Button
				onClick={handleClickOpen}
				style={{
					width: '5rem',
					backgroundColor: '#34A56F',
				}}
			>
				Enemy
			</Button>
			<Dialog
				fullWidth
				maxWidth={'md'}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<DialogTitle style={{ textAlign: 'center' }}>
					New Enemy Data
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction='row'
						alignItems='flex-end'
						justifyContent='space-between'
					>
						<Grid item>
							<Grid container direction='column'>
								<Grid item>
									<FormControl fullWidth>
										<InputLabel
											variant='standard'
											htmlFor='uncontrolled-native'
											style={{ fontWeight: '600' }}
										>
											Name
										</InputLabel>
										<Select
											style={{ width: 250 }}
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											value={enemy.Name}
											label='Name'
											onChange={(evt) =>
												setEnemy({
													...enemy,
													...defaultEnemy(
														evt.target.value,
														1,
														{enemyAssets: props.enemyAssets}
													),
												})
											}
										>
											{enemyClassNames.map(
												(item,index) => (
													<MenuItem
														key={index}
														value={item.Name}
													>
														{item.Name} - {item.Rank}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<FormControl fullWidth>
								<InputLabel
									variant='standard'
									htmlFor='uncontrolled-native'
									style={{ fontWeight: '600' }}
								>
									Level
								</InputLabel>
								<Select
									style={{ width: 80 }}
									value={enemy.Level}
									label='Level'
									onChange={(evt) =>
										setEnemy({
											...enemy,
											...defaultEnemy(
												enemy.Name,
												evt.target.value,
												{enemyClassNames}
											),
										})
									}
								>
									{levelsAvailable.map((level, index) => (
										<MenuItem key={index} value={level}>
											{level}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<InputLabel
								variant='standard'
								htmlFor='uncontrolled-native'
								style={{ fontSize: '1rem', fontWeight: '600' }}
							>
								HP
							</InputLabel>
							<TextField
								id='standard-number'
								//
								type='number'
								InputLabelProps={{
									shrink: true,
								}}
								variant='standard'
								style={{
									width: 90,
									textAlign: 'right',
									fontWeight: '600',
								}}
								value={enemy.HP}
								onChange={(evt) => {
									setEnemy({
										...enemy,
										HP: evt.target.value,
									});
								}}
							/>
						</Grid>
						<Grid item>
							<InputLabel
								variant='standard'
								htmlFor='uncontrolled-native'
								style={{ fontSize: '1rem', fontWeight: '600' }}
							>
								Number
							</InputLabel>
							<TextField
								id='standard-number'
								//
								type='number'
								InputProps={{ inputProps: { min: 1 } }}
								InputLabelProps={{
									shrink: true,
								}}
								variant='standard'
								style={{
									width: 90,
									textAlign: 'right',
									fontWeight: '600',
								}}
								value={enemy.Number}
								onChange={(evt) => {
									setEnemy({
										...enemy,
										Number: evt.target.value,
									});
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>CLOSE</Button>
					<Button onClick={handleSave}>SPAWN</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
