import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularColor() {
	return (
		<Stack sx={{ color: 'grey.500' }} spacing={2} direction='row'>
			<CircularProgress style={{ color: '#B80000' }} />
		</Stack>
	);
}
