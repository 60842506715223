import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Typography } from '@material-ui/core/';
import Box from '@mui/material/Box';
import Ability1 from './Ability1';
import Ability2 from './Ability2';
import Ability3 from './Ability3';
import Ability4 from './Ability4';
import PlayerGear from './PlayerGear';
import PlayerWeapons from './PlayerWeapons';
import UseButton from '../Player/UseButton/UseButton';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box style={{ padding: 1 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function allProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function PlayerAbilities(props) {
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const {
		Boots,
		Chest,
		Hands,
		Helm,
		Legs,
		Main_Hand,
		None,
		Off_Hand,
		Shoulders,
		Two_Handed,
	} = props.miscAssets.Gear;
	const armor = { Boots, Chest, Hands, Helm, Legs, Shoulders, None };
	const weapons = { None, Main_Hand, Off_Hand, Two_Handed };

	console.log(props.player)
	return (
		<div>
			<Box
				style={{
					borderBottom: 1,
					borderColor: 'divider',
					width: '100%',
				}}
			>
				<Tabs
					centered
					value={value}
					onChange={handleChange}
					style={{ justifyContent: 'center', fontSize: '1rem' }}
				>
					<Tab label='Ability 1' {...allProps(0)} />
					<Tab label='Ability 2' {...allProps(1)} />
					<Tab label='Ability 3' {...allProps(2)} />
					<Tab label='Ability 4' {...allProps(3)} />
					<Tab label='Armor' {...allProps(4)} />
					<Tab label='Weapons' {...allProps(5)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability1']['AbilityName']}:{' '}
							<UseButton ability={'Ability1'} diceResult={props.diceResult} player={props.player} enemyList={props.enemyList} updateEnemyList={props.updateEnemyList} updatePlayerList={props.updatePlayerList} playerIndex={props.playerIndex} damage={props.player['Ability1']['Damage'] + props.player['Power']} control={props.player['Ability1']['Control']} energy={props.player['Ability1']['AbilityEnergy']} healing={props.player['Ability1']['Healing']} range={props.player['Ability1']['AbilityRange']} threat={props.player['Ability1']['AbilityThreat']}/>
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability1']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability1 player={props.player} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability2']['AbilityName']}:{' '}
							<UseButton ability={'Ability2'} diceResult={props.diceResult} player={props.player} enemyList={props.enemyList} updateEnemyList={props.updateEnemyList} updatePlayerList={props.updatePlayerList} playerIndex={props.playerIndex} damage={props.player['Ability2']['Damage'] + props.player['Power']} control={props.player['Ability2']['Control']} energy={props.player['Ability2']['AbilityEnergy']} healing={props.player['Ability2']['Healing']} range={props.player['Ability2']['AbilityRange']} threat={props.player['Ability2']['AbilityThreat']}/>
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability2']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability2 player={props.player} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability3']['AbilityName']}:{' '}
							<UseButton ability={'Ability3'} diceResult={props.diceResult} player={props.player} enemyList={props.enemyList} updateEnemyList={props.updateEnemyList} updatePlayerList={props.updatePlayerList} playerIndex={props.playerIndex} damage={props.player['Ability3']['Damage'] + props.player['Power']} control={props.player['Ability3']['Control']} energy={props.player['Ability3']['AbilityEnergy']} healing={props.player['Ability3']['Healing']} range={props.player['Ability3']['AbilityRange']} threat={props.player['Ability3']['AbilityThreat']}/>
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability3']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability3 player={props.player} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability4']['AbilityName']}:{' '}
							<UseButton ability={'Ability4'} diceResult={props.diceResult} player={props.player} enemyList={props.enemyList} updateEnemyList={props.updateEnemyList} updatePlayerList={props.updatePlayerList} playerIndex={props.playerIndex} damage={props.player['Ability4']['Damage'] + props.player['Power']} control={props.player['Ability4']['Control']} energy={props.player['Ability4']['AbilityEnergy']} healing={props.player['Ability4']['Healing']} range={props.player['Ability4']['AbilityRange']} threat={props.player['Ability4']['AbilityThreat']}/>
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.player['Ability4']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability4 player={props.player} />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<PlayerGear
					player={props.player}
					playerIndex={props.playerIndex}
					updatePlayerList={props.updatePlayerList}
					armor={armor}
				/>
			</TabPanel>
			<TabPanel value={value} index={5}>
				<PlayerWeapons
					player={props.player}
					playerIndex={props.playerIndex}
					updatePlayerList={props.updatePlayerList}
					weapons={weapons}
				/>
			</TabPanel>
		</div>
	);
}
