import React, { useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HelmSelect from './SelectGear/HelmSelect';
import ShouldersSelect from './SelectGear/ShouldersSelect';
import ChestSelect from './SelectGear/ChestSelect';
import LegsSelect from './SelectGear/LegsSelect';
import HandsSelect from './SelectGear/HandsSelect';
import BootsSelect from './SelectGear/BootsSelect';
const defaultPlayer = require('./defaultPlayer');

export default function PlayerGear(props) {
	const [selectedGear,setSelectedGear] = useState('None');
  const Gear = props.armor;
  
  const updatePlayer = props.player ? props.player : defaultPlayer('Protector',1,{baseAssets: props.baseAssets,playerAssets: props.playerAssets});
  
  const getSelectedGear = (slot,selected) => {
    setSelectedGear(selected);
    if (selected !== 'None') {
      props.updatePlayerList(
        {
          ...updatePlayer,
          Gear: {
            ...updatePlayer['Gear'],
            [slot]: {
              Name: selected,
              Armor_Type: Gear[slot][selected]['Armor_Type'],
              Armor: Gear[slot][selected]['Armor'],
              Vitality: Gear[slot][selected]['Vitality'],
              Efficiency: Gear[slot][selected]['Efficiency'],
              Regen: Gear[slot][selected]['Regen'],
              Critical_Strike:
                Gear[slot][selected]['Critical_Strike'],
            },
          },
        },
        props.playerIndex
      );
    } else {
      props.updatePlayerList(
        {
          ...updatePlayer,
          Gear: {
            ...updatePlayer['Gear'],
            [slot]: {
              Name: '',
              Armor_Type: '',
              Armor: 0,
              Vitality: 0,
              Efficiency: 0,
              Regen: 0,
              Critical_Strike: 0,
            },
          },
        },
        props.playerIndex
      );
    }
  };	
 
  return (
    <TableContainer component={Paper} style={{justifyContent: 'center',backgroundColor: 'white'}}>
      <Table style={{ minWidth: 550 }} size="small" >
        <TableHead>
            <TableRow key={'columns'} style={{ fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
		  	    <TableCell align='left' style={{fontWeight: 'bold'}} >Slot</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}} >Name</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}} >Armor</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}} >Vitality</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}} >Efficiency</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}} >Regen</TableCell>
            <TableCell align='center' style={{fontWeight: 'bold'}} >Critical Strike</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={'Helm'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
          <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Helm</TableCell>
              <TableCell align="center" style={{fontSize: '0.6rem'}}><HelmSelect helm={props.armor.Helm} chosen={updatePlayer['Gear']['Helm']['Name']} getSelectedGear={getSelectedGear}/></TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Helm']['Armor']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Helm']['Vitality']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Helm']['Efficiency']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Helm']['Regen']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Helm']['Critical_Strike']}</TableCell>
            </TableRow>
            <TableRow key={'Shoulders'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
            <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Shoulders</TableCell>
              <TableCell align="center" style={{fontSize: '0.6rem'}}><ShouldersSelect shoulders={props.armor.Shoulders} chosen={updatePlayer['Gear']['Shoulders']['Name']} getSelectedGear={getSelectedGear}/></TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Shoulders']['Armor']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Shoulders']['Vitality']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Shoulders']['Efficiency']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Shoulders']['Regen']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Shoulders']['Critical_Strike']}</TableCell>
            </TableRow>
            <TableRow key={'Chest'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
            <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Chest</TableCell>
              <TableCell align="center" style={{fontSize: '0.6rem'}}><ChestSelect chest={props.armor.Chest} chosen={updatePlayer['Gear']['Chest']['Name']} getSelectedGear={getSelectedGear}/></TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Chest']['Armor']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Chest']['Vitality']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Chest']['Efficiency']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Chest']['Regen']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Chest']['Critical_Strike']}</TableCell>
          </TableRow>
          <TableRow key={'Legs'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
            <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Legs</TableCell>
              <TableCell align="center" style={{fontSize: '0.6rem'}}><LegsSelect legs={props.armor.Legs} chosen={updatePlayer['Gear']['Legs']['Name']} getSelectedGear={getSelectedGear}/></TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Legs']['Armor']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Legs']['Vitality']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Legs']['Efficiency']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Legs']['Regen']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Legs']['Critical_Strike']}</TableCell>
            </TableRow>
            <TableRow key={'Hands'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
            <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Hands</TableCell>
              <TableCell align="center" style={{fontSize: '0.6rem'}}><HandsSelect hands={props.armor.Hands} chosen={updatePlayer['Gear']['Hands']['Name']} getSelectedGear={getSelectedGear}/></TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Hands']['Armor']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Hands']['Vitality']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Hands']['Efficiency']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Hands']['Regen']}</TableCell>
              <TableCell align="center" >{updatePlayer['Gear']['Hands']['Critical_Strike']}</TableCell>
            </TableRow>
            <TableRow key={'Boots'} style={{fontSize: '0.75rem',backgroundColor: 'white',height: 10}}>
            <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align='left'>Boots</TableCell>
            <TableCell align="center" style={{fontSize: '0.6rem'}}><BootsSelect boots={props.armor.Boots} chosen={updatePlayer['Gear']['Boots']['Name']} getSelectedGear={getSelectedGear}/></TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Boots']['Armor']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Boots']['Vitality']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Boots']['Efficiency']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Boots']['Regen']}</TableCell>
            <TableCell align="center" >{updatePlayer['Gear']['Boots']['Critical_Strike']}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}