import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Grid, Typography } from '@material-ui/core/';
import HouseIcon from '@mui/icons-material/House';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
// import Login from './Authentication/Login';
// import Logout from './Authentication/Logout';
// import Signup from './Authentication/Signup';

import { Link } from 'react-router-dom';

const Header = (props) => {
	const [showSignup, setShowSignup] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	let myValue = props.myValue;
	let selectedIndex = props.selectedIndex;


	const routes = [
		{
			...props,
			activeIndex: 0,
			name: 'Welcome',
			link: '/',
			routeIcon: <HouseIcon fontSize='large' />,
		},
		{
			...props,
			activeIndex: 1,
			name: 'Combat Sim',
			link: '/CombatSim',
			routeIcon: <ImportantDevicesIcon fontSize='large' />,
		},
	];

	useEffect(() => {
		[...routes].forEach((route) => {
			switch (window.location.pathname) {
				case `${route.link}`:
					if (myValue !== route.activeIndex) {
						props.setMyValue(route.activeIndex);
						if (
							route.selectedIndex &&
							route.selectedIndex !== selectedIndex
						) {
							props.setSelectedIndex(route.selectedIndex);
						}
					}
					break;
				default:
					break;
			}
		});
	}, [routes, myValue, props, selectedIndex]);

	const handleShowSignup = () => {
		setShowSignup(true);
	};

	const handleHideSignup = () => {
		setShowSignup(false)
	}

	const handleShowLogin = () => {
		setShowLogin(true)
	};

	const handleHideLogin = () => {
		setShowLogin(false)
	};

	const handleLogout = () => {

	};

	return (
		<div>
			<AppBar position='static' style={{ backgroundColor: '#B80000' }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Grid item>
						<Toolbar
							disableGutters
							style={{ backgroundColor: '#B80000' }}
						>
							<Typography
								variant='h4'
								style={{
									color: 'white',
									textDecoration: 'none',
									boxShadow: 'none',
									cursor: 'pointer',
									padding: '5px',
								}}
								component={Link}
								to='/'
							>
								Kings of Taboron
							</Typography>
						</Toolbar>
					</Grid>
					{/* <Grid item>
						<Grid container direction='row' >
							{props.isLoggedIn ? (
								<Logout handleLogout={handleLogout} open={showLogin}/>
							) : (
								<>
									<Signup handleShowSignup={handleShowSignup} handleHideSignup={handleHideSignup} open={showSignup} /> <Login handleShowLogin={handleShowLogin} handleHideLogin={handleHideLogin} open={showLogin} />
								</>
							)}
						</Grid>
					</Grid> */}
				</Grid>
			</AppBar>
		</div>
	);
};

export default Header;
