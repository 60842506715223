import { createTheme } from '@mui/material/styles';

const kotBlack = '#000000';
const kotWhite = '#FFFFFF';
const kotRed = '#B80000';

export default createTheme({
    // root: {
    //     color: '#ffffff',
	// 	backgroundColor: '#3d3d3d'
    // },
	palette: {
		common: {
			black: kotBlack,
			red: kotRed,
			white: kotWhite,
		},
		primary: {
			main: kotBlack,
		},
		secondary: {
			main: kotRed,
		},
	},
	typography: {
		h2: {
			fontFamily: 'Helvetica',
			fontWeight: 700,
			fontSize: '2.5rem',
			lineHeight: 1.5,
		},
		h3: {
			fontWeight: 500,
			fontFamily: 'Helvetica',
			fontSize: '1.75rem',
			lineHeight: 1,
		},
		h4: {
			fontFamily: 'Helvetica',
			fontSize: '2.5rem',
			fontWeight: 500,
		},
		h6: {
			fontWeight: 500,
			fontFamily: 'Helvetica',
			fontSize: '1.2rem',
			lineHeight: 1,
		},
		subtitle1: {
			fontSize: '1.25rem',
			fontWeight: 300,
			color: kotRed,
		},
		subtitle2: {
			color: kotWhite,
			fontWeight: 300,
			fontSize: '1.25rem',
		},
		body1: {
			fontSize: '1.25rem',
			color: kotRed,
			fontWeight: 300,
		},
		caption: {
			fontSize: '1rem',
			fontWeight: 300,
			color: kotRed,
		},
		authData: {
			fontFamily: 'Helvetica',
			textTransform: 'none',
			fontWeight: 700,
			color: kotWhite,
			fontSize: '1rem',
		},
	}
});
