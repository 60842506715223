import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Typography } from '@material-ui/core/';
import Box from '@mui/material/Box';
import Ability1 from './Ability1';
import Ability2 from './Ability2';
import Ability3 from './Ability3';
import Ability4 from './Ability4';
// import Ability5 from './Ability5';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box style={{ padding: 1 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function allProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function EnemyAbilities(props) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Box
				style={{
					borderBottom: 1,
					borderColor: 'divider',
					width: '100%',
				}}
			>
				<Tabs
					centered
					value={value}
					onChange={handleChange}
					style={{ justifyContent: 'center', fontSize: '1rem' }}
				>
					<Tab
						disabled={props.enemy['Ability_1']['Name'] === ''}
						label='Ability 1'
						{...allProps(0)}
					/>
					<Tab
						disabled={props.enemy['Ability_2']['Name'] === ''}
						label='Ability 2'
						{...allProps(1)}
					/>
					<Tab
						disabled={props.enemy['Ability_3']['Name'] === ''}
						label='Ability 3'
						{...allProps(2)}
					/>
					<Tab
						disabled={!props.enemy['Ability_4']}
						label='Ability 4'
						{...allProps(3)}
					/>
					{/* <Tab disabled={props.enemy['Ability_5']['Name'] === ''} label='Ability 5' {...allProps(4)} /> */}
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_1']['Name']}:{' '}
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_1']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability1 enemy={props.enemy} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_2']['Name']}:{' '}
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_2']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability2 enemy={props.enemy} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_3']['Name']}:{' '}
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_3']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability3 enemy={props.enemy} />
			</TabPanel>
			<TabPanel
				disabled={props.enemy['Ability_4'] === ''}
				value={value}
				index={3}
			>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_4']['Name']}:{' '}
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_4']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability4 enemy={props.enemy} />
			</TabPanel>
			{/* <TabPanel
				disabled={props.enemy['Ability_5'] === ''}
				value={value}
				index={4}
			>
				<Grid container direction='row'>
					<Grid item>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_5']['Name']}:{' '}
						</Typography>
						<Typography style={{ fontSize: '1rem' }}>
							{props.enemy['Ability_5']['Function']}
						</Typography>
					</Grid>
				</Grid>
				<Ability5 enemy={props.enemy} />
			</TabPanel> */}
		</div>
	);
}
