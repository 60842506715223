const defaultPlayer = (Spec = 'Protector', level, props) => {
	const PlayerObjects = props.baseAssets['PlayerObjects'];

	const DataObj = PlayerObjects.find((item) => {
		if (item.Spec === Spec) {
			return item;
		}
	});
	
	const PlayerAssets = props.playerAssets;
	const XP = PlayerAssets.XPTrack;
	const playerLevelingArray = PlayerAssets.playerLevelingArray;

	const _valueCalc = (val, level) => {
		let output = val;
		for (let idx = 0; idx < level; idx++) {
			output = Math.ceil(output * playerLevelingArray[idx]); //always rounds up
		}
		return output;
	};

	return {
		Player_Name: '',
		Class: DataObj.Class,
		Spec: Spec,
		HP: _valueCalc(DataObj.HP, level),
		XP: 0,
		Req_XP: XP['xpArray'][level - 1],
		Base_HP: _valueCalc(DataObj.HP, level),
		Energy: DataObj['Energy'] ? DataObj['Energy'] : 10,
		Base_Energy: 10,
		Color: '#000000',
		Threat: 0,
		Base_Threat: DataObj['Base_Threat'] ? DataObj['Base_Threat'] : 2,
		Level: level,
		Status_Effects: [],
		Status: {
			poison: 0,
		},
		Armor: DataObj['Armor'],
		Movement: DataObj['Movement'],
		Base_Power: DataObj['Power'],
		Power: _valueCalc(DataObj['Power'], level),
		Base_Crit_Rating: DataObj['CritRating'],
		Base_Regen: 2,
		Miss_Chance: DataObj['BaseMissChance'],
		Total_Vitality: DataObj.HP,
		Total_Efficiency: 0,
		Total_Regen: 2,
		Total_Critical_Rating: DataObj['CritRating'],
		Ability1: {
			AbilityName: DataObj['Ability1']['Name'],
			// AbilityType: DataObj['Ability1']['AbilityType'],
			Function: DataObj['Ability1']['Function'],
			Control: DataObj['Ability1']['Control'],
			Base_Damage: DataObj['Ability1']['Damage'],
			Damage: _valueCalc(DataObj['Ability1']['Damage'], level),
			AbilityEnergy: DataObj['Ability1']['AbilityEnergy'],
			Base_Healing: DataObj['Ability1']['Healing'],
			Healing: _valueCalc(DataObj['Ability1']['Healing'], level),
			AbilityRange: DataObj['Ability1']['AbilityRange'],
			AbilityThreat: DataObj['Ability1']['AbilityThreat'],
		},
		Ability2: {
			AbilityName: DataObj['Ability2']['Name'],
			// AbilityType: DataObj['Ability2']['AbilityType'],
			Function: DataObj['Ability2']['Function'],
			Control: DataObj['Ability2']['Control'],
			Base_Damage: DataObj['Ability2']['Damage'],
			Damage: _valueCalc(DataObj['Ability2']['Damage'], level),
			AbilityEnergy: DataObj['Ability2']['AbilityEnergy'],
			Base_Healing: DataObj['Ability2']['Healing'],
			Healing: _valueCalc(DataObj['Ability2']['Healing'], level),
			AbilityRange: DataObj['Ability2']['AbilityRange'],
			AbilityThreat: DataObj['Ability2']['AbilityThreat'],
		},
		Ability3: {
			AbilityName: DataObj['Ability3']['Name'],
			// AbilityType: DataObj['Ability3']['AbilityType'],
			Function: DataObj['Ability3']['Function'],
			Control: DataObj['Ability3']['Control'],
			Base_Damage: DataObj['Ability3']['Damage'],
			Damage: _valueCalc(DataObj['Ability3']['Damage'], level),
			AbilityEnergy: DataObj['Ability3']['AbilityEnergy'],
			Base_Healing: DataObj['Ability3']['Healing'],
			Healing: _valueCalc(DataObj['Ability3']['Healing'], level),
			AbilityRange: DataObj['Ability3']['AbilityRange'],
			AbilityThreat: DataObj['Ability3']['AbilityThreat'],
		},
		Ability4: {
			AbilityName: DataObj['Ability4']['Name'],
			// AbilityType: DataObj['Ability4']['AbilityType'],
			Function: DataObj['Ability4']['Function'],
			Control: DataObj['Ability4']['Control'],
			Base_Damage: DataObj['Ability4']['Damage'],
			Damage: _valueCalc(DataObj['Ability4']['Damage'], level),
			AbilityEnergy: DataObj['Ability4']['AbilityEnergy'],
			Base_Healing: DataObj['Ability4']['Healing'],
			Healing: _valueCalc(DataObj['Ability4']['Healing'], level),
			AbilityRange: DataObj['Ability4']['AbilityRange'],
			AbilityThreat: DataObj['Ability4']['AbilityThreat'],
		},
		Gear: {
			Boots: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Chest: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Hands: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Helm: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Legs: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Main_Hand: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			None: {
				Name: 'None',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Off_Hand: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Shoulders: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
			Two_Handed: {
				Name: '',
				Armor_Type: '',
				Armor: 0,
				Vitality: 0,
				Efficiency: 0,
				Regen: 0,
				Critical_Strike: 0,
			},
		},
		Role_Abilities: {},
	};
};

module.exports = defaultPlayer;
