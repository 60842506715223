import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Select } from '@material-ui/core/';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino';
import Tooltip from '@material-ui/core/Tooltip';

export default function UseButton(props) {
	const [showTarget, setShowTarget] = useState(false);
	const [selectedEnemy, setSelectedEnemy] = useState('');
	const [hideAttack, setHideAttack] = useState(true);
	const [rollText, setRollText] = useState('Roll Dice...');

	const useStyles = makeStyles((theme) => ({
		targetStyle: {
			backgroundColor: 'white',
			height: '300px',
		},
	}));
	const classes = useStyles();

	const handleShowTarget = () => {
		setShowTarget(true);
	};

	const handleHideTarget = () => {
		setShowTarget(false);
		setSelectedEnemy('');
		setRollText('Roll Dice...');
	};

	const { damage, control, energy, healing, range, threat } = props;
	let arr = [];

	if (props.enemyList !== undefined && props.enemyList.length > 0) {
		props.enemyList.forEach((enemy) =>
			arr.push(
				enemy['Rank'] + ' - ' + enemy['Name'] + ' #' + enemy['Number']
			)
		);
	}

	// const applyAbility = () => {
	// 	if (
	// 		props.player['Energy'] >= energy &&
	// 		arr.indexOf(selectedEnemy) >= 0
	// 	) {
	// 	}
	// };
	let critPcnt = 0;
	let criticalStrikeStatSums = 0;
	function Dice(sides) {
		let roll = '';
		roll = Math.floor(Math.random() * sides) + 1;
		return roll;
	}

	function getDiceRoll() {
		const crit_Percentage = critPcnt;
		const crit_Percentage_Round = Math.ceil(critPcnt * 100) / 100;
		const miss = props.player.Miss_Chance;
		const D20 = Dice(20);
		const pcnt_D20 = D20 / 20;

		const crit = 1 - crit_Percentage_Round;
		const D100 = Dice(100);
		const pcnt_D100 = D100 / 100;

		// let miss_Result = pcnt_D20 >= miss ? 'HIT!' : 'Miss';
		let miss_Result = pcnt_D20 > miss ? 'HIT!' : 'Miss';
		let crit_Result =
			miss_Result !== 'Miss'
				? pcnt_D100 >= crit
					? 'CRITICAL STRIKE!'
					: 'Regular Attack'
				: 'Miss';
		// Regardless of hit/miss, player still loses energy and threat is increased
		if (
			props.player['Energy'] >= energy &&
			arr.indexOf(selectedEnemy) >= 0
		) {
			props.updatePlayerList(
				{
					...props.player,
					Energy: props.player['Energy'] - energy,
					Threat: props.player['Threat'] + threat,
				},
				props.playerIndex
			);

			console.log({
				level: props.player['Level'],
				['1_minus_crit']: crit,
				miss_chance: miss,
				crit_Percentage_Round,
				D20,
				D100,
				pcnt_D20,
				pcnt_D100,
				miss_Result,
				crit_Result,
				crit_Percentage,
				criticalStrikeStatSums,
				miss_Test: pcnt_D20 < miss,
				crit_test: pcnt_D100 >= crit,
			});
			miss_Result === 'HIT!'
				? setHideAttack(false) &&
				  setRollText(`${miss_Result} - ${crit_Result}`)
				: setHideAttack(true);
			setRollText(`${miss_Result} - ${crit_Result}`);

			if (miss_Result === 'HIT!') {
				const index = arr.indexOf(selectedEnemy);
				props.updateEnemyList(
					{
						...props.enemyList[index],
						HP: props.enemyList[index]['HP'] - damage,
					},
					index
				);
			}
			return {
				D20,
				D100,
				pcnt_D20,
				pcnt_D100,
				miss_Result,
				crit_Result,
				crit_Percentage,
			};
		}
	}

	function useButtonMessage(ability) {
		if (props.enemyList.length === 0) {
			return 'No enemies to attack';
		} else if (props.player[ability]['AbilityEnergy'] > props.player.Energy) {
			return 'Not enough energy';
		} else {
			return 'Good Luck!';
		}
	}

	return (
		<>
			<Tooltip title={useButtonMessage(props.ability)} placement='right'>
				<span>
					<Button
						disabled={
							props.player['Energy'] < energy ||
							props.enemyList.length === 0
						}
						size='small'
						variant='contained'
						style={{
							backgroundColor: 'blue',
							cursor: 'pointer',
							fontSize: '0.7rem',
						}}
						onClick={handleShowTarget}
					>
						Use
					</Button>
				</span>
			</Tooltip>
			<Dialog
				classes={{ paper: classes.targetStyle }}
				maxWidth={'sm'}
				open={showTarget}
				onClose={handleHideTarget}
			>
				<DialogContent>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'
						spacing={1}
					>
						<Grid item>
							<Typography variant='h5'>
								Select Desired Target then Roll
							</Typography>
						</Grid>
						<Grid item>
							<Grid item>
								<Select
									style={{
										width: 200,
									}}
									value={selectedEnemy}
									onChange={(evt) =>
										setSelectedEnemy(evt.target.value)
									}
								>
									{arr.map((enemy, index) => (
										<MenuItem key={index} value={enemy}>
											{enemy}
										</MenuItem>
									))}
								</Select>
							</Grid>
						</Grid>
						<Grid item>
							<CasinoIcon
								style={{
									fontSize: '2.5rem',
									color: '#7E3895',
									cursor: 'pointer',
								}}
								onClick={getDiceRoll}
							/>
						</Grid>
						<Grid item>
							<Typography variant='h5'>{rollText}</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container direction='row' justifyContent='center'>
						<Grid item>
							<Button
								onClick={() => {
									handleHideTarget();
								}}
							>
								{' '}
								Close
							</Button>
						</Grid>
						{/* <Grid item>
							<Button
								disabled={hideAttack || selectedEnemy === ''}
								onClick={() => applyAbility()}
							>
								ATTACK!
							</Button>
						</Grid> */}
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
}
