import React from 'react';
import { Grid } from '@material-ui/core/';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from "@material-ui/core/Paper";

const defaultPlayer = require('../Player/defaultPlayer');

export default function Ability4(props) {
	const newPlayer = props.player
		? props.player
		: defaultPlayer('Protector', 1,{baseAssets: props.baseAssets,playerAssets: props.playerAssets});

	return (
		<>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ width: '26rem' }}
				>
					<Grid item>
						<Box style={{ padding: '0.5rem', border: '1px solid black' }}>
							<TableContainer component={Paper}>
							<Table>
							<TableBody >
								<TableRow>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Damage</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newPlayer['Ability4']['Damage']}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Healing</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newPlayer['Ability4']['Healing']}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Control</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newPlayer['Ability4']['Control']}
									</TableCell>
								</TableRow>
							</TableBody>
							</Table>
							</TableContainer>
						</Box>
					</Grid>
					<Grid item>
						<Box style={{ padding: '0.5rem', border: '1px solid black' }}>
						<TableContainer component={Paper}>
							<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Energy</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newPlayer['Ability4']['AbilityEnergy']}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Threat</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newPlayer['Ability4']['AbilityThreat']}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>Range</TableCell>
									<TableCell style={{fontSize: '0.75rem', backgroundColor: 'white'}}>
										{newPlayer['Ability4']['AbilityRange']}
									</TableCell>
								</TableRow>
							</TableBody>
							</Table>
							</TableContainer>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
